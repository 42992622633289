<template>
  <div
    :class="[ (index % 2) ? 'bg-form-darker' : 'bg-form' ]"
  >
    <div
      @click="openGroup = !openGroup"
      class="d-flex align-center jc-space-between cursor-pointer fs-14 lh-24 px-24 h-48px test-monitoring-events-group-toggle"
    >
      <div class="color-text-darker">
        {{ date(group[0].time) }}
      </div>
      <div class="d-flex align-center color-text-lighter">
        <span>
          {{ group.length }} update{{ (group.length > 1) ? 's' : '' }}
        </span>
        <span
          class="ml-6"
          :class="{ 'rotate--180': openGroup }"
        >
          <Icon name="arrow-down-white" color="#60BCE3" />
        </span>
      </div>
    </div>
    <div
      v-if="openGroup"
      class="d-grid gap-y-4 px-24 pb-16"
    >
      <MonitoringEvents
        v-for="(event, eventIndex) in group"
        :key="event.id"
        :event="event"
        :profile="profile"
        :index="eventIndex"
        :newEvent="newEvents.includes(event.id)"
        :eventBg="[ (index % 2) ? 'bg-form' : 'bg-form-darker' ]"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import MonitoringEvents from '@/components/monitoring/Events';
import { exactDateFormat } from '@/utils/helpers';

export default {
  name: 'MonitoringEventsGroup',
  props: ['profile', 'newEvents', 'group', 'index', 'firstGroup'],
  components: {
    Icon,
    MonitoringEvents
  },
  data () {
    return {
      openGroup: this.firstGroup
    };
  },
  methods: {
    date (val) {
      return exactDateFormat(val);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
