<template>
  <div class="d-grid gtc-2 gap-x-12 px-24 py-16">
    <div>
      <div class="fs-11 fw-600 uppercase color-text">Text 1</div>
      <div class="fs-14 color-text bg-white b-all bc-form-border px-16 py-12 mt-4">
        {{ event.previous_value }}
      </div>
    </div>
    <div>
      <div class="fs-11 fw-600 uppercase color-text">Text 2</div>
      <div class="fs-14 color-text bg-white b-all bc-form-border px-16 py-12 mt-4">
        {{ event.current_value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonitoringEventText',
  props: ['event']
};
</script>
