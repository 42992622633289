<template>
  <div class="d-grid gtc-2 gap-x-12 px-24 py-16">
    <div>
      <div class="fs-11 fw-600 uppercase color-text">Photo 1</div>
      <div
        @click="viewCover = !viewCover"
        class="p-relative cursor-pointer br-5 w-100 h-0 pt-square bg-white overflow-hidden mt-4"
      >
        <img
          class="p-absolute top-0 left-0 d-block w-100 h-100 object-cover"
          :class="[ viewCover ? 'object-contain' : 'object-cover' ]"
          :src="event.previous_value"
        />
      </div>
    </div>
    <div>
      <div class="fs-11 fw-600 uppercase color-text">Photo 2</div>
      <div
        @click="viewCover = !viewCover"
        class="p-relative cursor-pointer br-5 w-100 h-0 pt-square bg-white overflow-hidden mt-4"
      >
        <img
          class="p-absolute top-0 left-0 d-block w-100 h-100 object-cover"
          :class="[ viewCover ? 'object-contain' : 'object-cover' ]"
          :src="event.current_value"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonitoringEventPhoto',
  props: ['event'],
  data () {
    return {
      viewCover: false
    };
  }
};
</script>
