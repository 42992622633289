<template>
  <div class="shadow-big br-5 mb-20">
    <div
      class="d-flex jc-space-between align-center px-24 py-12 bg-form br-top-left-5 br-top-right-5"
      :class="{ 'br-5' : !openCase }"
    >
      <h2
        @click="openCase = !openCase"
        class="cursor-pointer d-flex align-center fw-600 fs-16 lh-24 color-text-darker test-monitoring-toggle"
      >
        <span v-if="investigation.tag.length < 40">{{ investigation.tag }}</span>
        <span v-else>{{ investigation.tag.substring(0, 40) + '...' }}</span>
        <span
          class="lh-24 ml-10"
          :class="{ 'rotate--180' : openCase }"
        >
          <Icon
            class="mb-1"
            name="arrow-down-white"
            color="#60BCE3"
          />
        </span>
      </h2>
      <div class="d-flex align-center">
        <router-link
          :to="{ name: 'MonitoringSettings', params: { case_id: this.investigation.oid }}"
          class="d-flex align-center cursor-pointer test-monitoring-edit"
        >
          <Icon
            class="icon-hover ml-10"
            name="edit"
            color="#60BCE3"
            bg="#ffffff"
          />
        </router-link>
        <span
          class="d-flex align-center cursor-pointer test-monitoring-remove"
          @click="setCaseToRemove(investigation)"
        >
          <Icon
            class="icon-hover ml-10"
            name="trash"
            color="#60BCE3"
            bg="#ffffff"
          />
        </span>
        <p
          class="fw-600 fs-13 lh-24 let-space-08 color-text-lighter ml-25"
          v-html="nextPayment"
        ></p>
        <div class="onoffswitch">
          <input
            type="checkbox"
            name="onoffswitch"
            class="onoffswitch-checkbox test-monitoring-case-payment"
            :id="'case-switch-' + caseID"
            tabindex="0"
            v-model="active"
            @change="updateMonitoringTasks"
          >
          <label class="onoffswitch-label" :for="'case-switch-' + caseID"></label>
        </div>
      </div>
    </div>
    <div v-show="openCase">
      <div
        v-for="(task, index) in tasks"
        :key="task.id"
        class="bc-body b-bottom bg-white"
        :class="{ 'br-bottom-left-5 br-bottom-right-5': index === (tasks.length - 1) }"
      >
        <MonitoringTask
          :task="task"
          :caseID="caseID"
          :caseTitle="investigation.tag"
          :firstTask="firstCase && index === 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MonitoringTask from '@/components/monitoring/Task';
import Icon from '@/components/app/Icon';
import { exactDateFormat } from '@/utils/helpers';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'MonitoringCase',
  props: ['investigation', 'firstCase'],
  data () {
    return {
      openCase: this.firstCase
    };
  },
  components: {
    MonitoringTask,
    Icon
  },
  computed: {
    ...mapGetters(['accountState', 'monitoringTasks']),
    active: {
      get: function () {
        return !this.tasks.every(task => task.paused);
      },
      set: function (val) {
        this.pauseMonitoringTasks({
          caseID: this.caseID,
          pause: !val
        });
      }
    },
    caseID () {
      return this.investigation.oid;
    },
    tasks () {
      return this.monitoringTasks[this.caseID] || [];
    },
    nextPayment () {
      const transforms = this.accountState.transforms;
      const pipelines = [];
      this.tasks.forEach(task => {
        if (!task.paused) {
          let sheduledAt = task.pipelines.scheduled_at;
          if (String(sheduledAt).length === 10) sheduledAt = sheduledAt * 1000;
          if (new Date(sheduledAt) > new Date()) {
            pipelines.push({
              price: transforms.find(tr => tr.name === task.pipelines.code).price,
              date: task.pipelines.scheduled_at
            });
          }
        }
      });
      let finalPrice = '<span class="color-link">0 slp</span>';
      if (pipelines.length) {
        const sortedPipelines = pipelines.sort((a, b) => a.date - b.date);
        finalPrice = `next payment: 
                      <span class="color-link">${sortedPipelines[0].price} slp</span> 
                      at ${exactDateFormat(sortedPipelines[0].date)}`;
      }

      return finalPrice;
    }
  },
  methods: {
    ...mapActions(['updateMonitoringTask', 'getMonitoringTasks']),
    ...mapMutations(['setCaseToRemove', 'pauseMonitoringTasks']),
    async updateMonitoringTasks () {
      await this.updateMonitoringTask({
        caseID: this.caseID,
        paused: !this.active,
        period: '24h'
      });
      await this.getMonitoringTasks(this.caseID);
    }
  }
};
</script>
