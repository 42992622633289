<template>
  <div class="let-space-04 py-16">
    <div class="d-flex align-center justify-between px-24 pb-12">
      <div class="d-flex align-center">
        <Icon name="person-card-linkedin" />
        <Avatar
          class="w-24px h-24px br-circle overflow-hidden ml-10"
          :src="profileAvatar"
        />
        <div class="d-flex align-center">
          <button
            @click="showEvents = !showEvents"
            class="test-monitoring-task-toggle"
          >
            <p
              class="fs-14 lh-24 let-space-04 color-text-darker ml-12"
              :class="{ 'cursor-auto': !task.total_events }"
            >
              {{ profileName }}
            </p>
            <span
              v-if="task.total_events"
              class="lh-24 ml-6"
              :class="{ 'rotate--180': showEvents }"
            >
              <Icon name="arrow-down-white" color="#60BCE3" class="mb-1" />
            </span>
          </button>
          <div
            v-if="newEvents.length"
            class="w-6px h-6px br-circle bg-link lh-24 ml-15"
          ></div>
        </div>
      </div>
      <div class="d-flex align-center">
        <p class="fw-600 fs-13 lh-24 let-space-08 color-text-lighter">
          <span class="color-link">{{ price }} slp</span> {{ paymentPeriod }}
        </p>
        <div class="onoffswitch">
          <input
            type="checkbox"
            name="onoffswitch"
            class="onoffswitch-checkbox test-monitoring-task-payment"
            v-model="active"
            :id="'profile-switch-' + task.id"
            tabindex="0"
            @change="updateTask"
          >
          <label class="onoffswitch-label" :for="'profile-switch-' + task.id"></label>
        </div>
      </div>
    </div>

    <div class="mx-24">
      <div
        class="d-flex align-center jc-space-between w-100 fs-14 color-text-darker transition bg-form bg-form-darker_hover br-5 lh-24 px-24 py-16 test-monitoring-events-toggle"
        :class="{ 'cursor-pointer' : task.total_events, 'br-bottom-left-0 br-bottom-right-0' : showEvents }"
        @click.prevent="showEvents = !showEvents"
      >
        <div
          v-if="task.pipelines.setuped"
          class="task-info"
        >
          <span v-if="task.pipelines.setuped_at">
            Started from {{ date(task.pipelines.setuped_at) }}
          </span>
          <span v-if="task.pipelines.synced_at">
            last launch {{ date(task.pipelines.synced_at) }}
          </span>
          <span v-if="showNextLaunch">
            next launch {{ date(task.pipelines.scheduled_at) }}
          </span>
        </div>
        <div v-else>Monitoring already started</div>
        <div
          v-if="task.total_events"
          class="color-link"
        >
          {{ showEvents ? `Hide` : `Show ${sortedGroups.length} updates` }}
        </div>
      </div>
    </div>
    <div
      v-if="task.total_events"
      v-show="showEvents"
      class="mt-4 mx-24"
    >
      <div class="d-flex align-center jc-space-between bg-form-darker px-24 h-48px mb-4">
        <div class="fs-14">
          {{ sortedGroups.length }} update{{ (sortedGroups.length > 1) ? 's' : '' }}
        </div>
        <!-- <div>
          <a class="d-flex align-center uppercase fs-11 fw-600 color-link color-link-lighter_hover transition ml-18">
            <Icon name="calendar" />
            <span class="ml-5">choose date range</span>
          </a>
        </div> -->
      </div>
      <div class="d-grid gap-y-4 br-bottom-left-5 br-bottom-right-5 overflow-hidden">
        <MonitoringEventsGroup
          v-for="(group, index) in sortedGroups"
          :key="index"
          :profile="profile"
          :newEvents="newEvents"
          :group="group"
          :index="index"
          :firstGroup="firstTask && index === 0"
        />
      </div>
    </div>
    <div class="mt-12 mx-24">
      <div class="d-flex align-center jc-space-between">
        <div class="d-flex align-center">
          <a
            class="d-flex align-center uppercase fs-11 fw-600 color-link color-link-lighter_hover transition mr-18"
            @click.prevent="toSingleProfile"
          >
            <Icon
              name="edit"
              color="#60BCE3"
            />
            <span class="ml-5">Edit</span>
          </a>
          <a
            @click.prevent="setTaskToRemove(task)"
            class="d-flex align-center uppercase fs-11 fw-600 color-link color-link-lighter_hover transition"
          >
            <Icon name="trash-no-fill" color="#60BCE3" />
            <span class="ml-10">Delete</span>
          </a>
        </div>
        <div>
          <!-- <a class="d-flex align-center uppercase fs-11 fw-600 color-link color-link-lighter_hover transition">
            <Icon name="document-text-small" color="#60BCE3" />
            <span class="ml-10">Fast report</span>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/app/Avatar';
import Icon from '@/components/app/Icon';
import MonitoringEventsGroup from '@/components/monitoring/EventsGroup';
import { getEntityAvatar, getEntityName, exactDateFormat } from '@/utils/helpers';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'MonitoringTask',
  props: ['task', 'caseID', 'firstTask'],
  components: {
    Avatar,
    Icon,
    MonitoringEventsGroup
  },
  data () {
    return {
      showEvents: this.firstTask
    };
  },
  computed: {
    ...mapGetters(['monitoredProfiles', 'accountState']),
    active: {
      get: function () {
        return !this.task.paused;
      },
      set: function (val) {
        this.$store.commit('pauseMonitoringTasks', {
          caseID: this.caseID,
          taskID: this.task.id,
          pause: !val
        });
      }
    },
    profile () {
      return this.monitoredProfiles[this.caseID].find(profile => profile.oid === this.task.entity_id);
    },
    profileName () {
      return getEntityName(this.profile);
    },
    profileAvatar () {
      return getEntityAvatar(this.profile);
    },
    price () {
      const pipelines = this.task.pipelines.code;
      const transforms = this.accountState.transforms;
      return transforms.find(tr => tr.name === pipelines).price;
    },
    paymentPeriod () {
      const time = Math.floor(this.task.pipelines.period / 1000 / 86400 / 1000000);
      let result;
      if (time === 30) result = 'per month';
      else if (time === 7) result = 'per week';
      else if (time === 1) result = 'per day';
      else result = 'per 12 hours';
      return result;
    },
    sortedGroups () {
      const events = this.task.events.slice().sort((a, b) => b.time - a.time);
      const groups = [];
      let arrIndex = -1;
      events.forEach((el, index) => {
        let prevTime;
        events[index - 1] ? prevTime = events[index - 1].time : prevTime = null;
        if (el.time !== prevTime) {
          arrIndex += 1;
          groups.push([]);
        }
        groups[arrIndex].push(el);
      });

      return groups;
    },
    newEvents () {
      const viewedTime = new Date(this.task.viewed_at);
      const newEvents = [];
      if (this.task.events) {
        this.task.events.forEach(event => {
          if (viewedTime < new Date(event.time)) newEvents.push(event.id);
        });
      }
      return newEvents;
    },
    showNextLaunch () {
      let scheduledAt = this.task.pipelines.scheduled_at;
      if (String(scheduledAt).length === 10) scheduledAt = scheduledAt * 1000;
      return scheduledAt && this.active && (new Date(scheduledAt) > new Date());
    }
  },
  methods: {
    ...mapMutations(['setTaskToRemove']),
    async updateTask () {
      await this.$store.dispatch('updateMonitoringTask', {
        caseID: this.caseID,
        taskID: this.task.id,
        paused: !this.active,
        period: this.task.period + 'ns'
      });
      await this.$store.dispatch('getMonitoringTasks', this.caseID);
    },
    date (val) {
      return exactDateFormat(val);
    },
    deleteTask () {
      this.$emit('deleteTask', this.task);
    },
    toSingleProfile () {
      this.$router.push({
        name: 'MonitoringSettings',
        params: {
          case_id: this.task.case_id,
          profile_id: this.task.entity_id,
          task_id: this.task.id
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
  .task-info {
    span{
      &:first-child {
        text-transform: capitalize;
      }
      &:not(:last-child):after {
        content: ',';
      }
    }
  }
</style>
