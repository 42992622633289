<template>
  <div class="br-5 overflow-hidden b-all bc-link-border-lighter">
    <div
      class="d-flex align-center jc-space-between cursor-pointer transition fs-14 lh-24 let-space-04 py-12 px-24 bg-white test-monitoring-event-toggle"
      @click="opened = !opened"
    >
      <div class="d-flex align-center">
        <p class="color-text-darker">
          Text has changed in the '{{ eventField }}' field
        </p>
        <div
          v-if="newEvent"
          class="w-4px h-4px br-circle bg-link ml-10"
        ></div>
      </div>
      <div class="d-flex align-center justify-between">
        <span
          :class="{ 'rotate--180': opened }"
        >
          <Icon name="arrow-down-white" color="#60BCE3" />
        </span>
      </div>
    </div>
    <div
      v-if="opened"
      :class="eventBg"
    >
      <template v-if="event.field === 'photo'">
        <MonitoringEventPhoto
          :event="event"
        />
      </template>
      <template v-else>
        <MonitoringEventText
          :event="event"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import MonitoringEventPhoto from '@/components/monitoring/EventPhoto';
import MonitoringEventText from '@/components/monitoring/EventText';
import { exactDateFormat } from '@/utils/helpers';

export default {
  name: 'MonitoringEvents',
  components: {
    Icon,
    MonitoringEventPhoto,
    MonitoringEventText
  },
  props: ['event', 'profile', 'newEvent', 'eventBg'],
  data () {
    return {
      opened: false
    };
  },
  computed: {
    eventField () {
      return this.event.field.replaceAll('_', ' ');
    }
  },
  methods: {
    date (val) {
      return exactDateFormat(val);
    }
  }
};
</script>
